<script setup>
import { ref, computed } from 'vue'
import store from '@/store'
const props = defineProps(['address', 'youOn'])
const name = ref()
const isYou = computed(() => props.address.toLowerCase() === store.state.address?.toLowerCase())
const onVisible = async () => {
  try {
    if (props.youOn && isYou.value) return
    if (!name.value) {
      name.value = await store.dispatch('getAddressName', props.address)
    }
  } catch (e) {
    console.error(e)
  }
}
</script>

<template lang="pug">
a.addr(v-intersect, @intersect="onVisible", :href="$store.getters.openSeaLink({ account: props.address })", target="_blank", rel="noopener noreferrer")
  template(v-if="isYou && props.youOn") You
  template(v-else) {{ name || $store.getters.addrShort(props.address) }}
</template>

export default [
  {
    id: 1,
    name: 'pets.com',
    years: '1998-2000',
    description: 'a website that sold pet-related supplies to retail customers',
    auctionInfo: 'Twardowski summoned the pet purgatory to bring their most loved mascot back to life. Unlike the other invocations, <b>pets.com</b> is a single spirit, destined to live forever alone. As such, this resurrected entity will be auctioned on <b>OpenSea</b>',
    auctionLink: 'https://opensea.io'
  },
  {
    id: 2,
    name: 'alladvantage.com',
    years: '1999-2001',
    description: 'a web browser that paid users for looking at advertisements while browsing'
  },
  {
    id: 3,
    name: 'bidland.com',
    years: '1999-2001',
    description: 'a service provider enabling websites to run auctions'
  },
  {
    id: 4,
    name: 'bizbuyer.com',
    years: '1998-2001',
    description: 'a website which listed contract jobs for businesses to bid on'
  },
  {
    id: 5,
    name: 'capacityweb.com',
    years: '2000-2001',
    description: 'a platform that facilitated the leasing of manufacturing companies’ production downtime'
  },
  {
    id: 6,
    name: 'cashwars.com',
    years: '1999-2001',
    description: 'a free online role playing game which awarded winners money'
  },
  {
    id: 7,
    name: 'ecircles.com',
    years: '1998-2001',
    description: 'an image sharing social network allowing users to share photos to friends in closed groups'
  },
  {
    id: 8,
    name: 'efanshop.com',
    years: '1999-2001',
    description: 'a company that managed logistics and technology for sports web shops'
  },
  {
    id: 9,
    name: 'ehobbies.com',
    years: '1999-2001',
    description: 'a website selling hobbyist products and equipment'
  },
  {
    id: 10,
    name: 'elaw.com',
    years: '2000-2001',
    description: 'a searchable database of downloadable legal documents for lawyers'
  },
  {
    id: 11,
    name: 'exchangepath.com',
    years: '1997-2001',
    description: 'a website facilitating person-to-person money transfers via credit card and mail-out cheque'
  },
  {
    id: 12,
    name: 'financialprinter.com',
    years: '1999-2001',
    description: 'a website that produced and distributed financial and legal documents for companies'
  },
  {
    id: 13,
    name: 'funbug.com',
    years: '1999-2001',
    description: 'a game platform that allowed users to earn and spend a proprietary private currency that could be converted to fiat'
  },
  {
    id: 14,
    name: 'heavenlydoor.com',
    years: '2000-2001',
    description: 'a directory website for burial-related businesses'
  },
  {
    id: 15,
    name: 'iharvest.com',
    years: '1997-2001',
    description: 'a personal internet archive service that enabled users to save and store static copies of webpages'
  },
  {
    id: 16,
    name: 'mrswap.com',
    years: '2000-2001',
    description: 'a platform where users could arrange mail swaps and bartering of CDs and DVDs of music, video games and videos'
  },
  {
    id: 17,
    name: 'netmorf.com',
    years: '1998-2001',
    description: 'a software platform that pushed desktop web content to contemporary mobile devices'
  },
  {
    id: 18,
    name: 'popularpower.com',
    years: '2000-2001',
    description: 'an online marketplace that enabled users to pool the processing power of their computers to form a distributed computing network'
  },
  {
    id: 19,
    name: 'stickynetworks.com',
    years: '1999-2000',
    description: 'a platform that allowed users to paste pop-up messages on any website'
  },
  {
    id: 20,
    name: 'thirdvoice.com',
    years: '1999-2001',
    description: 'a platform that allowed users to leave virtual sticky notes on any website'
  },
  {
    id: 21,
    name: 'wingspanbank.com',
    years: '1999-2001',
    description: 'an early internet-only bank which charged no fees to users'
  }
]
